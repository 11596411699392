import React from 'react';
import { Link } from 'react-router-dom';
import './index.css';

const Dashboard = () => {
  return (
    <div>
      <h1>Что это дешборд</h1>
      <Link to="/" className="btn btn-primary">Выход</Link>
    </div>
  );
}

export default Dashboard;
